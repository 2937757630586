import { Grid } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Text from 'commons/Text';

import useStyles from './styles';

const GalleryArtist = ({ listArtist }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div>
      <Grid
        container
        spacing={2}
        sx={{ mt: 2 }}
      >
        {listArtist.map((artist, index) => (
          <Grid
            item
            xs={12}
            lg={4}
            key={index}
            sx={{ mb: 2 }}
          >
            <Text fontWeightMedium>
              {artist?.name_artist} {artist?.year_of_birth ? `(B.${artist.year_of_birth})` : ''}
            </Text>
            <Text
              type="sm"
              className={classes.textArtworkCount}
            >
              {artist?.artwork_count} {t('artworks')}
            </Text>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default GalleryArtist;
