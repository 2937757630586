import clsx from 'clsx';
import React from 'react';

import Image from 'commons/Image';

import useStyles from './styles';

const ImageSectionSameArtist = ({ artworks }) => {
  const classes = useStyles();

  const [artwork1] = artworks || [];

  const subArtworks = artworks?.slice(1, 4) || [];

  return (
    <div className={classes.container}>
      <div className={classes.mainImageBlock}>
        <Image
          src={artwork1?.images[0]}
          className={clsx(classes.image, { [classes.noneImage]: !artwork1?.images[0] })}
        />
      </div>

      <div className={classes.wrapImage}>
        {subArtworks?.map((image, index) => (
          <Image
            key={index}
            src={image?.images[0]}
            className={classes.subImage}
          />
        ))}
      </div>
    </div>
  );
};

export default ImageSectionSameArtist;
