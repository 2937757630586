import LanguageIcon from '@mui/icons-material/Language';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Box } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import BoxStatusArtwork from 'commons/BoxStatusArtwork';
import CheckBoxCustom from 'commons/CheckBoxCustom';
import ConfirmationOption from 'commons/ConfirmationOption';
import DialogArtistName from 'commons/DialogArtistName';
import DialogCollection from 'commons/DialogCollection';
import DialogLocation from 'commons/DialogLocation';
import FormInput from 'commons/Form/FormInput';
import FormInputDescription from 'commons/FormInputDescription';
import FormInputPeriod from 'commons/FormInputPeriod';
import GroupButtonArtwork from 'commons/GroupButtonArtwork';
import RadioCustom from 'commons/RadioCustom';
import SelectCollections from 'commons/SelectCollections';
import SelectLocation from 'commons/SelectLocation';
import SelectSubjects from 'commons/SelectSubjects';
import Text from 'commons/Text';
import YearSelector from 'commons/YearSelector';
import API from 'configs/API';
import {
  DateTimeFormat,
  OPTIONS_DIMENSION_UNITS,
  TESTID,
  USER_ROLE_BY_KEY,
  getLabelForOption,
} from 'configs/Constant';
import AutocompleteWithOptionExtend from 'features/Artwork/AutocompleteWithOptionExtend';
import useArtworkDropdown from 'hooks/useArtworkDropdown';
import useRole from 'hooks/useRole';
import useSavedUser from 'hooks/useSavedUser';
import useUploadImageS3 from 'hooks/useUploadImageS3';
import { useArtworkContext } from 'pages/UploadArtwork/ArtworkContext/useArtworkContext';
import Routes from 'utils/Route';
import {
  isValidEmailAddress,
  isValidPhoneNumber,
  isValidSizeInput,
  validateArtworkStepTwo,
} from 'utils/Validation';
import { axiosGet, axiosPost } from 'utils/apis/axios';
import { ToastTopHelper } from 'utils/utils';

import ListItemMeasurement from '../../../../commons/ListItemMeasurement';
import FormArtistByRoleArtist from './components/FormArtistByRoleArtist';
import FormArtistByRoleCollector from './components/FormArtistByRoleCollector';
import UploadArtworkEditionUIView from './components/UploadArtworkEditionUiView';
import useStyles from './styles';

const { ARTIST, COLLECTOR } = USER_ROLE_BY_KEY;
const OPTION_FORM_ARTIST_BY_ROLE = {
  [ARTIST]: FormArtistByRoleArtist,
  [COLLECTOR]: FormArtistByRoleCollector,
};

const ArtWorkStepTwo = ({ step, categoryProp, listImage, setStep, setSelectCategory }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { isArtist, isCollector } = useRole();

  const { artworkData, validationStatusPrice } = useArtworkContext();
  const user = useSelector((state) => state.auth.account.user);
  const userRole = useSelector((state) => state.auth.account.user.role);
  const language = useSelector((state) => state?.language?.currentLanguage);
  const { uploadListImage } = useUploadImageS3();
  const { dropdowns } = useArtworkDropdown(categoryProp);
  const { mutate: savedUser } = useSavedUser();

  const { price, currency, isHidePrice, isAskPrice, visibility, contact } = artworkData;
  const [description, setDescription] = useState('');
  const [title, setTittle] = useState('');
  const [note, setNote] = useState('');
  const [status, setStatus] = useState(null);
  const [style, setStyle] = useState(null);
  const [orientation, setOrientation] = useState(null);
  const [medium, setMedium] = useState(null);
  const [size, setSize] = useState({
    height: '',
    width: '',
    depth: '',
  });

  const [isDisabled, setIsDisabled] = useState(false);
  const [selectYear, setSelectYear] = useState(null);
  const [checkedPeriod, setCheckedPeriod] = useState(false);
  const [period, setPeriod] = useState('');
  const [totalEdition, setTotalEdition] = useState(1);
  const [editionNumber, setEditionNumber] = useState(1);
  const [location, setLocation] = useState('');
  const [optionLocaltion, setoptionLocaltion] = useState([]);
  const [optionCollection, setOptionCollection] = useState([]);
  const [locationName, setLocationName] = useState('');
  const [collectionName, setCollectionName] = useState('');
  const [collectionDescription, setCollectionDescription] = useState('');
  const [piece, setPiece] = useState(1);
  const [selectedConfirmnOption, setSelectedConfirmnOption] = useState({});
  const [unitDimension, setUnitDimension] = useState(OPTIONS_DIMENSION_UNITS[0].key);
  const [isShowDialogLocation, setIsShowDialogLocation] = useState(false);
  const [isShowDialogCollection, setIsShowDialogCollection] = useState(false);
  const [isShowDialogArtistName, setIShowDialogArtistName] = useState(false);
  const [isShowInputArtistName, setIsShowInputArtistName] = useState(false);
  const [nameUser, setNameUser] = useState('');
  const [emailOrPhoneUser, setEmailOrPhoneUser] = useState('');

  const [checkedNotSureArtist, setCheckedNotSureArtist] = useState(false);
  const [inputUser, setInputUser] = useState('');
  const [selectUser, setSelectUser] = useState(null);
  const [isShowCheckBoxCustom, setIsShowCheckBoxCustom] = useState(true);
  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const [selectedCollections, setSelectedCollections] = useState([]);

  const DisplayFormArtist = OPTION_FORM_ARTIST_BY_ROLE[userRole];

  const handleInputUserChange = (e, newValue) => {
    setInputUser(newValue);
  };

  const handleUserChange = (e, newValue) => {
    setSelectUser(newValue || null);
  };

  const handleClickPaper = () => () => {
    setIShowDialogArtistName(true);
  };

  const handleChangeTitle = (e) => {
    setTittle(e.target.value);
  };

  const handleChangeDescription = (e) => {
    const inputValue = e.target.value;
    if (inputValue?.length > 6000) {
      return;
    }
    setDescription(inputValue);
  };

  const handleChangeNote = (e) => {
    const inputValue = e.target.value;
    if (inputValue?.length > 200) {
      return;
    }
    setNote(inputValue);
  };

  const handleOptionChange = (item) => {
    setOrientation(item);
  };

  const handleSelectSection = (item) => {
    if (item.id === 1) {
      setPiece(2);
    } else {
      setPiece(1);
    }
    setSelectedConfirmnOption(item);
  };

  const handleBackToPreviousStep = () => {
    setStep((prevStep) => prevStep - 1);
    setSelectCategory({});
  };

  const handleDateChange = (date) => {
    setSelectYear(date);
  };

  const handleCheckBox = (event) => {
    setCheckedPeriod(event.target.checked);
    setSelectYear(null);
    setPeriod('');
  };

  const handleCheckArtist = (e) => {
    setCheckedNotSureArtist(e?.target?.checked);

    if (e.target.checked) {
      setSelectUser(null);
    }
  };

  const handleChangePeriod = (e) => {
    const inputValue = e.target.value;
    if (inputValue?.length > 200) {
      return;
    }
    setPeriod(inputValue);
  };

  const handleChangeTotalEdition = (e) => {
    const newValue = e.target.value;
    const intValue = parseInt(newValue, 10);

    const isValidPositiveInteger = Number.isInteger(intValue) && intValue >= 1;
    const isWithinDigitLimit = newValue.length <= 8;
    const valueToSet = newValue === '' ? '' : intValue;

    if ((isValidPositiveInteger && isWithinDigitLimit) || newValue === '') {
      setTotalEdition(valueToSet);
    }
  };

  const handleChangeEditionNumber = (e) => {
    const newValue = e.target.value;
    const intValue = parseInt(newValue, 10);

    const isValidPositiveInteger = Number.isInteger(intValue) && intValue >= 1;
    const valueToSet = newValue === '' ? '' : intValue;

    if (isValidPositiveInteger || newValue === '') {
      setEditionNumber(valueToSet);
    }
  };

  const handleChangeSize = (e) => {
    const { name, value } = e.target;

    if (value === '' || !isValidSizeInput(value)) {
      setSize((prev) => ({ ...prev, [name]: '' }));
      return;
    }

    const floatValue = parseFloat(value);
    const integerPart = floatValue.toString().split('.')[0];
    if (floatValue <= 0 || integerPart.length > 8) {
      setSize((prev) => ({ ...prev, [name]: '' }));
      return;
    }
    setSize((prev) => ({ ...prev, [name]: value }));
  };

  const handleChangeLocationName = (e) => {
    const value = e.target.value;
    if (value?.length > 50) {
      return;
    }
    setLocationName(value);
  };

  const handleChangeCollectionName = (e) => {
    const value = e.target.value;
    if (value?.length > 50) {
      return;
    }
    setCollectionName(value);
  };

  const handleChangeCollectionDescription = (e) => {
    const value = e.target.value;
    setCollectionDescription(value);
  };

  const handleCancelDialogLocation = () => {
    setLocationName('');
    setIsShowDialogLocation(false);
  };

  const handleCancelDialogCollection = () => {
    setCollectionName('');
    setCollectionDescription('');
    setIsShowDialogCollection(false);
  };

  const handleOptionsDimensionUnit = (e) => {
    const value = e.target.value;
    setUnitDimension(value);
  };

  const getUserLocation = async () => {
    const { success, data } = await axiosGet(API.AUTH.LOCATION);
    if (success) {
      setoptionLocaltion(data);
    }
  };

  const getUserCollection = async () => {
    const { success, data } = await axiosGet(API.ARTWORK.LIST_OWNER_COLLECTION);
    if (success) {
      setOptionCollection(data);
    }
  };

  const handleSubmitLocation = async () => {
    const filterLocation = optionLocaltion.map((item) => item.location);
    const isNameInFilter = filterLocation.includes(locationName);
    if (isNameInFilter) {
      ToastTopHelper.error(t('the_location_name_already_exists'));
      return;
    }
    const { success, data } = await axiosPost(API.AUTH.LOCATION, {
      user: user.id,
      location: locationName,
    });
    if (success) {
      getUserLocation();
      setLocation(data.id);
      setLocationName('');
    }
    setIsShowDialogLocation(false);
  };

  const handleSubmitCollection = async () => {
    const formData = {
      title: collectionName,
      description: collectionDescription,
      owner: user.id,
      is_public: false,
    };
    const { success } = await axiosPost(API.ARTWORK.COLLECTION, formData);

    if (success) {
      getUserCollection();
      setCollectionName('');
      setCollectionDescription('');
    }
    setIsShowDialogCollection(false);
  };

  const handleChangeArtistName = (e) => {
    const value = e.target.value;
    if (value?.length > 50) {
      return;
    }
    setNameUser(value);
  };
  const handleChangeEmailPhoneArtist = (e) => {
    const value = e.target.value;
    if (value?.length > 50) {
      return;
    }
    setEmailOrPhoneUser(value);
  };

  const handleCancelDialogArtistName = () => {
    setIShowDialogArtistName(false);
    setNameUser('');
    setEmailOrPhoneUser('');
  };

  const handleSubmitArtistName = () => {
    if (!nameUser) {
      ToastTopHelper.error(t('please_enter_artist_name'));
      return;
    }
    if (emailOrPhoneUser) {
      if (!isValidPhoneNumber(emailOrPhoneUser) && !isValidEmailAddress(emailOrPhoneUser)) {
        ToastTopHelper.error(t('please_enter_correct_email_phone_number'));
        return;
      }
    }

    savedUser(
      { user: user?.id, name_saved_user: nameUser, contact_info_saved_user: emailOrPhoneUser },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: [API.AUTH.SAVED_USER] });
        },
      },
    );

    setIShowDialogArtistName(false);
    setIsShowInputArtistName(true);
    setSelectUser(null);
    setIsShowCheckBoxCustom(false);
  };

  const handleClickSelectSaveUser = () => (item) => {
    setNameUser(item?.name_saved_user);
    setEmailOrPhoneUser(item?.contact_info_saved_user);
    setIsShowInputArtistName(true);
    setIsShowCheckBoxCustom(false);
    setSelectUser(null);
  };

  const handleSearchAgain = () => {
    setNameUser('');
    setEmailOrPhoneUser('');
    setSelectUser(null);
    setIsShowInputArtistName(false);
    setIsShowCheckBoxCustom(true);
  };

  const errors = validateArtworkStepTwo({
    t,
    listImage,
    status,
    validationStatusPrice,
    title,
    selectYear,
    period,
    medium,
    selectedConfirmnOption,
    piece,
    size,
    totalEdition,
    isCollector,
    selectedArtist: selectUser?.id,
    inputArtist: inputUser,
    isUpdate: false,
    imageKey: null,
    style,
    nameUser,
    needValidateArtist: !checkedNotSureArtist,
    isAskPrice,
    contact,
    editionNumber,
  });

  const getAritst = () => {
    if (isArtist) {
      return user;
    }

    if (isCollector && !checkedNotSureArtist) {
      if (selectUser?.id) {
        return selectUser;
      }
    }

    return null;
  };

  const createArtwork = async () => {
    let tagRequest = null;
    const artist = getAritst();
    let imagesKey = listImage.map((image) => image?.imageKey);

    if (artist) {
      tagRequest = {
        request_by: user?.id,
        request_to: artist?.id,
      };
    }

    const formData = {
      title: title,
      description: description,
      note: note,
      images: imagesKey,
      category: categoryProp?.key,
      status: status?.key,
      orientation: orientation?.id,
      size: size,
      is_public: visibility?.value,
      is_price_visible: isAskPrice ? false : isHidePrice,
      is_ask_price_visible: isAskPrice,
      price: price,
      currency: currency,
      year_created: selectYear?.format(DateTimeFormat.YYYY),
      period_created: period,
      total_edition: totalEdition,
      location: location,
      piece: piece,
      owner: user?.id,
      contact_information: contact,
      contact_info_artist: emailOrPhoneUser,
      collections: selectedCollections,
      subjects: selectedSubjects,

      ...(!!style && { style }),
      ...(!!medium && { medium }),
    };

    if (isArtist) {
      formData.artist = artist?.id;
    }

    if (isCollector) {
      formData.edition_number = editionNumber;
      formData.artist_name = artist?.name || nameUser;
      formData.tag_request = tagRequest;
    }

    const { success } = await axiosPost(API.ARTWORK.ARTWORK, formData);

    if (success) {
      ToastTopHelper.success(t('create_successfully'));
      queryClient.invalidateQueries({ queryKey: [API.ARTWORK.FILTER.STYLE] });
      queryClient.invalidateQueries({ queryKey: [API.ARTWORK.FILTER.SUBJECT] });
      queryClient.invalidateQueries({ queryKey: [API.ARTWORK.FILTER.MEDIUM] });

      navigate(Routes.GalleryDetail(user?.uuid));
    } else {
      ToastTopHelper.error(t('create_artwork_failed'));
      setIsDisabled(false);
    }
  };

  const handleSubmit = async () => {
    if (errors.length) {
      ToastTopHelper.error(errors[0]);
      return;
    }
    setIsDisabled(true);

    const uploadS3Response = await uploadListImage(listImage);

    const allSuccessful = uploadS3Response.every((response) => response?.success === true);

    if (allSuccessful) {
      createArtwork();
    } else {
      ToastTopHelper.error(t('upload_file_failed'));
      setIsDisabled(false);
    }
  };

  const handleCheckboxChange = (subjectId) => {
    setSelectedSubjects((prevSelected) =>
      prevSelected.includes(subjectId)
        ? prevSelected.filter((id) => id !== subjectId)
        : [...prevSelected, subjectId],
    );
  };

  const handleDeleteItemSubject = (subjectId) => {
    setSelectedSubjects((prevSelected) => prevSelected.filter((id) => id !== subjectId));
  };

  const handleSelectCollections = (subjectId) => {
    setSelectedCollections((prevSelected) =>
      prevSelected.includes(subjectId)
        ? prevSelected.filter((id) => id !== subjectId)
        : [...prevSelected, subjectId],
    );
  };

  const handleDeleteItemCollection = (subjectId) => {
    setSelectedCollections((prevSelected) => prevSelected.filter((id) => id !== subjectId));
  };

  useEffect(() => {
    getUserLocation();
    getUserCollection();
  }, []);

  if (!DisplayFormArtist) {
    return;
  }

  return (
    <div>
      <GroupButtonArtwork
        textButtonOne={t('next')}
        onClickButtonOne={handleSubmit}
        disabledButtonOne={isDisabled}
        step={step}
        textButtonTwo={t('back')}
        onClickButtonTwo={handleBackToPreviousStep}
        testid={TESTID.BUTTON_ARTWORK}
      />

      <div className={classes.scrollbarBox}>
        <BoxStatusArtwork
          statusOption={dropdowns?.status}
          status={status}
          setStatus={setStatus}
        />

        <Box className={clsx(classes.wrapBoxContent, classes.wrapBoxCustom)}>
          <FormInput
            value={title}
            isRequired
            onChange={handleChangeTitle}
            label={t('artwork_title')}
            placeholder={t('give_your_artwork_title')}
            labelStyle={classes.formInputLabel}
            textFieldStyle={classes.textFieldStyle}
            wrapFormInputStyle={classes.wrapFormInput}
            testid={`${TESTID.TEXT_FIELD}_TITLE`}
            labelProps={{
              type: 'lg',
              fontWeightBold: true,
            }}
          />

          <DisplayFormArtist
            isShowInputArtistName={isShowInputArtistName}
            checkedNotSureArtist={checkedNotSureArtist}
            nameUser={nameUser}
            isShowCheckBoxCustom={isShowCheckBoxCustom}
            handleCheckArtist={handleCheckArtist}
            handleClickPaper={handleClickPaper}
            handleSearchAgain={handleSearchAgain}
            handleClickSelectSaveUser={handleClickSelectSaveUser}
            value={selectUser}
            onChange={handleUserChange}
            inputValue={inputUser}
            onInputChange={handleInputUserChange}
            classes={classes}
          />

          <YearSelector
            selectYear={selectYear}
            disabled={checkedPeriod}
            isRequired
            onChangeDate={handleDateChange}
            testid={TESTID.YEAR_SELECTOR}
          />

          <CheckBoxCustom
            textCheckBox={t('show_creation_year_as_period')}
            checked={checkedPeriod}
            onChange={handleCheckBox}
            testid={TESTID.CHECK_BOX_PERIOD_ARTWORK}
          />

          {checkedPeriod && (
            <FormInputPeriod
              placeholder={t('before_20th_century')}
              textInputPeriod={t('platform_can_not_sort_by_period')}
              value={period}
              onChange={handleChangePeriod}
              testid={TESTID.FORM_INPUT_PERIOD_ARTWORK}
            />
          )}

          <UploadArtworkEditionUIView
            artistEditionProps={{
              totalEdition,
              onTotalEditionChange: handleChangeTotalEdition,
            }}
            collectionEditionProps={{
              editionNumber: editionNumber,
              onEditionNumberChange: handleChangeEditionNumber,
              editionVolume: totalEdition,
              onEditionVolumeChange: handleChangeTotalEdition,
            }}
          />

          <AutocompleteWithOptionExtend
            value={style}
            categoryProp={categoryProp}
            onChange={(value) => setStyle(value)}
            options={dropdowns?.style}
            label={t('style')}
            placeholder={t('select_style_your_artwork')}
            apiOption={API.ARTWORK.FILTER.STYLE}
            labelProps={{
              fontWeightBold: true,
              type: 'lg',
            }}
            testid={`${TESTID.AUTO_COMPLETE_CUSTOME}_STYLE`}
            maxLengthCounter={100}
          />

          <SelectSubjects
            selectedSubjects={selectedSubjects}
            dropdownSubject={dropdowns?.subject}
            handleCheckboxChange={handleCheckboxChange}
            handleDeleteItemSubject={handleDeleteItemSubject}
            fontWeightBold={true}
            typeText="lg"
            mbText={6}
            mtText={14}
          />

          <AutocompleteWithOptionExtend
            value={medium}
            categoryProp={categoryProp}
            onChange={(value) => setMedium(value)}
            options={dropdowns?.medium}
            isRequired
            label={t('medium_on_material')}
            placeholder={t('select_medium_on_material')}
            apiOption={API.ARTWORK.FILTER.MEDIUM}
            labelProps={{
              fontWeightBold: true,
              type: 'lg',
            }}
            testid={`${TESTID.AUTO_COMPLETE_CUSTOME}_MEDIUM`}
            maxLengthCounter={100}
          />

          <ConfirmationOption
            selectedConfirmnOption={selectedConfirmnOption}
            piece={piece}
            setPiece={setPiece}
            onSelectSection={handleSelectSection}
          />

          <ListItemMeasurement
            size={size}
            optionsDimensionUnits={OPTIONS_DIMENSION_UNITS}
            unitDimension={unitDimension}
            onOptionsDimensionUnit={handleOptionsDimensionUnit}
            onChangeSize={(e) => handleChangeSize(e)}
          />

          <Text
            fontWeightBold
            type="lg"
            mb={6}
            mt={14}
          >
            {t('orientation')}
          </Text>
          <div>
            {dropdowns?.orientation.map((item, index) => (
              <RadioCustom
                item={item}
                key={index}
                keyLabel={item.id}
                value={getLabelForOption(item, language)}
                selectedOption={orientation}
                onOptionChange={() => handleOptionChange(item)}
                testid={`${TESTID.AUTO_COMPLETE_CUSTOME}_ORIENTATION`}
              />
            ))}
          </div>

          <SelectLocation
            location={location}
            optionLocaltion={optionLocaltion}
            setLocation={setLocation}
            setIsShowDialog={setIsShowDialogLocation}
          />

          <SelectCollections
            selectedCollections={selectedCollections}
            optionCollection={optionCollection}
            handleCheckboxChange={handleSelectCollections}
            handleDeleteItem={handleDeleteItemCollection}
            setIsShowDialog={setIsShowDialogCollection}
            fontWeightBold={true}
            typeText="lg"
            mbText={6}
            mtText={14}
          />

          <FormInputDescription
            value={description}
            maxLength={6000}
            onChange={handleChangeDescription}
            label={t('description')}
            icon={<LanguageIcon className={classes.icon} />}
            placeholder={t('write_your_description_about_the_artwork')}
            labelStyle={classes.formInputLabel}
            textAreaStyle={classes.textAreaDescription}
            wrapFormInputStyle={classes.wrapFormInputDescription}
            testid={`${TESTID.TEXT_FIELD}_DESCRIPTION`}
          />

          <FormInputDescription
            value={note}
            maxLength={200}
            onChange={handleChangeNote}
            label={t('note')}
            icon={<LockOutlinedIcon className={classes.icon} />}
            placeholder={t('write_your_note_about_the_artwork')}
            labelStyle={classes.formInputLabel}
            textAreaStyle={classes.textAreaNote}
            wrapFormInputStyle={classes.wrapFormInputDescription}
            testid={`${TESTID.TEXT_FIELD}_NOTE`}
          />
        </Box>
      </div>

      <DialogLocation
        isShowDialog={isShowDialogLocation}
        value={locationName}
        disabledButton={!locationName}
        onChange={handleChangeLocationName}
        onSubmit={handleSubmitLocation}
        onCancel={handleCancelDialogLocation}
      />

      <DialogArtistName
        isShowDialog={isShowDialogArtistName}
        onChangeArtistName={handleChangeArtistName}
        onChangeEmailPhoneArtist={handleChangeEmailPhoneArtist}
        onCancel={handleCancelDialogArtistName}
        onSubmit={handleSubmitArtistName}
      />

      <DialogCollection
        isShowDialog={isShowDialogCollection}
        name={collectionName}
        description={collectionDescription}
        disabledButton={!collectionName}
        onChangeName={handleChangeCollectionName}
        onChangeDescription={handleChangeCollectionDescription}
        onSubmit={handleSubmitCollection}
        onCancel={handleCancelDialogCollection}
      />
    </div>
  );
};

export default ArtWorkStepTwo;
