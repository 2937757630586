import React from 'react';

import ListArtwork from './components/ListArtwork';
import useStyles from './styles';

const EligibleArtworks = ({ artworks }) => {
  const classes = useStyles();

  const listArtwork = artworks?.results || [];

  return (
    <div className={classes.wrapAllWork}>
      <ListArtwork listArtwork={listArtwork} />
    </div>
  );
};

export default EligibleArtworks;
