import { Box, FormLabel, Grid } from '@mui/material';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import clsx from 'clsx';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import FormInput from 'commons/Form/FormInput';
import FormAutocompleteController from 'commons/FormHook/FormAutocompleteController';
import FormCheckBoxController from 'commons/FormHook/FormCheckBoxController';
import FormArtistByRoleCollector from 'commons/FormUserByRoleCollector';
import Measurement from 'commons/Measurement';
import SelectSubjects from 'commons/SelectSubjects';
import Text from 'commons/Text';
import TextArea from 'commons/TextArea';
import API from 'configs/API';
import { USER_ROLE_BY_KEY, getLabelForOption } from 'configs/Constant';
import AutocompleteWithOptionExtendController from 'features/Artwork/AutocompleteWithOptionExtendController';
import useRole from 'hooks/useRole';
import useSavedUser from 'hooks/useSavedUser';
import { isValidEmailAddress, isValidPhoneNumber } from 'utils/Validation';
import { ToastTopHelper } from 'utils/utils';

import EditArtworkSection from '../../../../commons/BoxSection';
import FormArtistByRoleArtist from './components/FormArtistByRoleArtist';
import useCancelTagRequest from './hooks/useCancelTagRequest';
import useDropDown from './hooks/useDropDown';
import useStyles from './styles';

const { ARTIST, COLLECTOR } = USER_ROLE_BY_KEY;

const OPTION_FORM_ARTIST_BY_ROLE = {
  [ARTIST]: FormArtistByRoleArtist,
  [COLLECTOR]: FormArtistByRoleCollector,
};

const EditArtworkInfo = ({ artworkFormState, artwork }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const user = useSelector((state) => state.auth.account.user);
  const queryClient = useQueryClient();

  const DisplayFormArtist = OPTION_FORM_ARTIST_BY_ROLE[user?.role];
  const { isCollector } = useRole();

  const { register, formState, control, setValue, watch, getValues } = artworkFormState;
  const {
    checkedPeriod,
    editions,
    inputSearchUser,
    artistName,
    contactInfoArtist,
    isShowDialogArtistName,
    isShowDialogCancelTagRequest,
    artistTagRequest,
    subjects,
  } = watch();

  const searchTimeout = useRef();

  const { mutate: cancelTagRequest } = useCancelTagRequest();
  const { mutate: savedUser } = useSavedUser();
  const { data: userFilter = [] } = useQuery({
    queryKey: [
      API.AUTH.SEARCH_USER,
      { search: inputSearchUser, role__in: USER_ROLE_BY_KEY.ARTIST, exclude__in: user?.id },
    ],
  });

  const language = useSelector((state) => state?.language?.currentLanguage);

  const hasCertificate = formState?.defaultValues?.hasCertificate;

  const { styleDropdown, subjectDropdown, mediumDropdown, orientationDropdown, isPending } =
    useDropDown({
      artwork,
    });

  const updateYearCreatedValue = (value) => {
    setValue('yearCreated', value, { shouldValidate: true });
    setValue('periodYear', '', { shouldValidate: false });
  };

  const updatePeriodYearValue = (value) => {
    setValue('yearCreated', '', { shouldValidate: false });
    setValue('periodYear', value, { shouldValidate: true });
  };

  const handleChangeYearCreated = (e) => {
    const { value } = e.target;
    updateYearCreatedValue(value);
  };

  const handleChangePeriodYear = (e) => {
    const { value } = e.target;
    updatePeriodYearValue(value);
  };

  const handleCheckArtist = (e) => {
    const checked = e.target.checked;
    if (checked) {
      handleClearData();
    }
  };

  const handleCheckBoxChange = (e) => {
    const checked = e.target.checked;
    const { defaultValues } = formState;

    if (checked) {
      updatePeriodYearValue(defaultValues?.periodYear);
    } else {
      updateYearCreatedValue(defaultValues?.yearCreated);
    }
  };

  const handleClearData = () => {
    setValue('selectedUser', null, { shouldValidate: true });
    setValue('artistName', '', { shouldValidate: true });
    setValue('inputSearchUser', '');
  };

  const handleInputSearchUser = (e) => {
    const value = e?.target?.value || '';
    if (searchTimeout.current) {
      clearTimeout(searchTimeout.current);
    }
    searchTimeout.current = setTimeout(() => {
      setValue('inputSearchUser', value);
    }, 500);
  };

  const handleClickPaper = () => () => {
    setValue('isShowDialogArtistName', true);
  };

  const handleSelectUser = (e, newValue) => {
    setValue('selectedUser', newValue || null, { shouldValidate: true });
  };

  const handleChangeArtistName = (e) => {
    const value = e.target.value;
    if (value?.length > 50) {
      return;
    }
    setValue('artistName', value);
  };
  const handleChangeEmailPhoneArtist = (e) => {
    const value = e.target.value;
    if (value?.length > 50) {
      return;
    }
    setValue('contactInfoArtist', value);
  };

  const handleCancelDialogArtistName = () => {
    setValue('isShowDialogArtistName', false);
    setValue('artistName', '');
    setValue('contactInfoArtist', '');
  };

  const handleSearchAgain = () => {
    setValue('artistName', '');
    setValue('contactInfoArtist', '');
    setValue('selectedUser', null, { shouldValidate: true });
    setValue('inputSearchUser', '');
    setValue('isShowCheckBoxCustom', true);
    setValue('isShowInputArtistName', false);
    setValue('isShowRejectTagRequest', false);
  };

  const handleSubmitArtistName = () => {
    if (!artistName) {
      ToastTopHelper.error(t('please_enter_artist_name'));
      return;
    }
    if (contactInfoArtist) {
      if (!isValidPhoneNumber(contactInfoArtist) && !isValidEmailAddress(contactInfoArtist)) {
        ToastTopHelper.error(t('please_enter_correct_email_phone_number'));
        return;
      }
    }

    savedUser(
      { user: user?.id, name_saved_user: artistName, contact_info_saved_user: contactInfoArtist },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: [API.AUTH.SAVED_USER] });
        },
      },
    );

    setValue('isShowDialogArtistName', false);
    setValue('isShowInputArtistName', true);
    setValue('selectedUser', null, { shouldValidate: true });
    setValue('isShowCheckBoxCustom', false);
  };

  const handleClickCancelRequest = () => {
    setValue('isShowDialogCancelTagRequest', true);
  };

  const handleCancelDialogTagRequest = () => {
    setValue('isShowDialogCancelTagRequest', false);
  };

  const handleClickSelectSaveUser = () => (item) => {
    setValue('artistName', item?.name_saved_user);
    setValue('contactInfoArtist', item?.contact_info_saved_user);
    setValue('isShowInputArtistName', true);
    setValue('isShowCheckBoxCustom', false);
    setValue('selectedUser', null, { shouldValidate: true });
  };

  const handleSubmitCancelTagRequest = () => {
    cancelTagRequest(artistTagRequest?.id, {
      onSuccess: () => {
        setValue('selectedUser', null, { shouldValidate: true });
        setValue('artistName', '');
        setValue('isShowCheckBoxCustom', true);
        setValue('isShowInputArtistName', false);
        setValue('isShowDialogCancelTagRequest', false);
      },
    });
  };

  const handleCheckboxChange = (subjectId) => {
    const currentSubjects = getValues('subjects') || [];
    const updatedSubjects = currentSubjects?.includes(subjectId)
      ? currentSubjects.filter((id) => id !== subjectId)
      : [...currentSubjects, subjectId];
    setValue('subjects', updatedSubjects);
  };

  const handleDeleteItemSubject = (subjectId) => {
    const currentSubjects = getValues('subjects') || [];
    const updatedSubjects = currentSubjects?.filter((id) => id !== subjectId);
    setValue('subjects', updatedSubjects);
  };

  if (isPending) {
    return;
  }

  if (!DisplayFormArtist) {
    return;
  }

  return (
    <EditArtworkSection
      sx={editions?.length > 0 && { mt: 2 }}
      heading={t('artwork_information')}
    >
      <Box sx={{ pt: 4 }}>
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={12}
            lg={6}
          >
            <FormInput
              {...register('title')}
              label={t('artwork_title')}
              wrapFormInputStyle={classes.formControl}
              labelStyle={classes.labelStyle}
              error={!!formState?.errors.title}
              helperText={formState?.errors.title?.message}
              disabled={hasCertificate}
              isRequired
            />
          </Grid>

          <Grid
            item
            xs={12}
            lg={6}
          >
            <Text
              type="lg"
              mb={2}
              className={classes.textArtist}
            >
              {t('artist')}
            </Text>
            <DisplayFormArtist
              artworkFormState={artworkFormState}
              hasCertificate={hasCertificate}
              userFilter={userFilter}
              titleSaveUser={t('new_artist_profile')}
              titleListUser={t('active_artists')}
              handleCheckArtist={handleCheckArtist}
              handleInputSearchUser={handleInputSearchUser}
              handleClickPaper={handleClickPaper}
              handleSelectUser={handleSelectUser}
              handleSearchAgain={handleSearchAgain}
              handleClearSelectUser={handleSearchAgain}
              handleClickCancelRequest={handleClickCancelRequest}
              handleClickSelectSaveUser={handleClickSelectSaveUser}
              dialogArtistName={{
                isShowDialog: isShowDialogArtistName,
                onChangeArtistName: handleChangeArtistName,
                onChangeEmailPhoneArtist: handleChangeEmailPhoneArtist,
                onCancel: handleCancelDialogArtistName,
                onSubmit: handleSubmitArtistName,
              }}
              dialogCancelTagRequest={{
                isShowDialog: isShowDialogCancelTagRequest,
                onCancel: handleCancelDialogTagRequest,
                onSubmit: handleSubmitCancelTagRequest,
              }}
            />
          </Grid>

          <Grid
            item
            xs={12}
            lg={6}
          >
            {!checkedPeriod && (
              <FormInput
                {...register('yearCreated', { valueAsNumber: true })}
                onChange={handleChangeYearCreated}
                label={t('creation_year')}
                error={!!formState?.errors?.yearCreated}
                helperText={!checkedPeriod && formState?.errors.yearCreated?.message}
                disabled={checkedPeriod || hasCertificate}
                inputProps={{ type: 'number', step: '1' }}
                isRequired
                labelStyle={classes.formInputLabel}
                wrapFormInputStyle={classes.formControl}
                textFieldStyle={clsx({
                  [classes.disableTextFieldStyle]: checkedPeriod,
                })}
              />
            )}
            {checkedPeriod && (
              <FormInput
                {...register('periodYear')}
                onChange={handleChangePeriodYear}
                label={t('period_year')}
                error={!!formState?.errors?.periodYear}
                helperText={formState?.errors.periodYear?.message}
                placeholder={t('before_20th_century')}
                disabled={hasCertificate}
                isRequired
                labelStyle={classes.formInputLabel}
                wrapFormInputStyle={classes.formControl}
              />
            )}

            <FormCheckBoxController
              name="checkedPeriod"
              control={control}
              onChange={handleCheckBoxChange}
              error={!!formState?.errors.checked}
              disabled={hasCertificate}
              helperText={formState?.errors.checked?.message}
              textCheckBox={t('show_creation_year_as_period')}
            />
          </Grid>

          {isCollector && (
            <Grid
              item
              xs={12}
              lg={6}
              sx={{
                display: {
                  xs: 'none',
                  lg: 'block',
                },
              }}
            />
          )}

          <Grid
            item
            xs={12}
            lg={6}
          >
            <FormInput
              {...register('editionVolume')}
              label={t('edition_volume')}
              isRequired
              error={!!formState?.errors?.editionVolume}
              helperText={formState?.errors.editionVolume?.message}
              inputProps={{ type: 'number', step: '1', 'aria-label': t('edition_volume') }}
              wrapFormInputStyle={classes.formControl}
              disabled
            />
          </Grid>

          {isCollector && (
            <Grid
              item
              xs={12}
              lg={6}
            >
              <FormInput
                {...register('edition.edition_number')}
                label={t('edition_number')}
                isRequired
                error={!!formState?.errors?.edition?.edition_number}
                helperText={formState?.errors?.edition?.edition_number?.message}
                InputProps={{
                  inputProps: { type: 'number', step: '1' },
                }}
                wrapFormInputStyle={classes.formControl}
                disabled={hasCertificate}
              />
            </Grid>
          )}

          <Grid
            item
            xs={12}
            lg={6}
          >
            <AutocompleteWithOptionExtendController
              control={control}
              name={'style'}
              options={styleDropdown || []}
              categoryProp={artwork?.category}
              apiOption={API.ARTWORK.FILTER.STYLE}
              helperText={formState?.errors.style?.message}
              error={!!formState?.errors.style}
              placeholder={t('select_style_your_artwork')}
              label={t('style')}
              labelProps={{
                type: 'sm',
              }}
              disabled={hasCertificate}
              autocompleteStyle={classes.autocompleteStyle}
              wrapFormStyle={classes.formControl}
            />
          </Grid>

          <Grid
            item
            xs={12}
            lg={6}
          >
            <SelectSubjects
              selectedSubjects={subjects}
              dropdownSubject={subjectDropdown}
              handleCheckboxChange={handleCheckboxChange}
              handleDeleteItemSubject={handleDeleteItemSubject}
              typeText="sm"
              mbText={2}
              formControlStyle={classes.formControlStyle}
            />
          </Grid>

          <Grid
            item
            xs={12}
            lg={6}
          >
            <AutocompleteWithOptionExtendController
              control={control}
              name={'medium'}
              options={mediumDropdown || []}
              categoryProp={artwork?.category}
              apiOption={API.ARTWORK.FILTER.MEDIUM}
              helperText={formState?.errors.medium?.message}
              error={!!formState?.errors.medium}
              placeholder={t('select_medium_on_material')}
              label={t('medium')}
              labelProps={{
                type: 'sm',
              }}
              disabled={hasCertificate}
              isRequired
              autocompleteStyle={classes.autocompleteStyle}
              wrapFormStyle={classes.formControl}
            />
          </Grid>

          <Grid
            item
            xs={12}
            lg={6}
          >
            <FormAutocompleteController
              control={control}
              name={'orientation'}
              options={orientationDropdown || []}
              label={t('orientation')}
              labelProps={{
                type: 'sm',
              }}
              placeholder={t('select_orientation')}
              getOptionLabel={(option) => getLabelForOption(option, language)}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              helperText={formState?.errors.orientation?.message}
              error={!!formState?.errors.orientation}
              disabled={hasCertificate}
              autocompleteStyle={classes.autocompleteStyle}
              wrapFormStyle={classes.formControl}
            />
          </Grid>
        </Grid>

        <Measurement
          register={register}
          formState={formState}
          disabled={hasCertificate}
        />

        <Box sx={{ mt: 2 }}>
          <FormLabel>
            <Text
              variant="span"
              type="sm"
            >
              {t('description')}
            </Text>
          </FormLabel>

          <TextArea
            {...register('description')}
            value={watch('description')}
            textMaxLength={6000}
            placeholder={t('write_your_description_about_the_artwork')}
            helperText={formState?.errors.description?.message}
            error={!!formState?.errors.description}
          />
        </Box>

        <Box sx={{ mt: 2 }}>
          <Text type="sm">
            <FormLabel>{t('note')}</FormLabel>
          </Text>

          <TextArea
            {...register('note')}
            value={watch('note')}
            textMaxLength={200}
            placeholder={t('write_your_note_about_the_artwork')}
            helperText={formState?.errors.note?.message}
            error={!!formState?.errors.note}
          />
        </Box>
      </Box>
    </EditArtworkSection>
  );
};

export default EditArtworkInfo;
