import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import API from 'configs/API';
import useRole from 'hooks/useRole';

import ArtistReviewTagRequest from './ArtistReviewTagRequest';
import CollectorReviewTagRequest from './CollectorReviewTagRequest';

const ReviewTagRequest = () => {
  const { isArtist, isCollector } = useRole();
  const { id } = useParams();
  const user = useSelector((state) => state.auth.account.user);

  const { data, isPending } = useQuery({
    queryKey: [API.ARTWORK.REVIEW_ARTIST_TAG_REQUEST(id)],
    gcTime: 0,
  });

  if (isPending) {
    return;
  }

  return (
    <>
      {isArtist && (
        <ArtistReviewTagRequest
          data={data}
          user={user}
        />
      )}
      {isCollector && (
        <CollectorReviewTagRequest
          data={data}
          user={user}
        />
      )}
    </>
  );
};

export default ReviewTagRequest;
