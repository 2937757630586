import { Stack } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Text from 'commons/Text';
import { TESTID, getLabelForOption } from 'configs/Constant';
import Section from 'features/Certificate/Section';

import useStyles from './styles';

const ReviewArtworkInformation = ({ data }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const language = useSelector((state) => state?.language?.currentLanguage);

  const { artwork } = data;

  return (
    <Section
      title={t('artwork_information')}
      wrapperStyle={classes.wrapper}
    >
      <Stack
        direction="row"
        sx={{ mt: 2 }}
        spacing={5}
      >
        <div>
          <Text type="H4">{t('artwork_title')}</Text>
          <Text
            type="H4"
            mt={16}
          >
            {t('artist_name')}
          </Text>
          <Text
            type="H4"
            mt={16}
          >
            {t('creation_year')}
          </Text>
          <Text
            type="H4"
            mt={16}
          >
            {t('edition_volume')}
          </Text>
          <Text
            type="H4"
            mt={16}
          >
            {t('style')}
          </Text>
          <Text
            type="H4"
            mt={16}
          >
            {t('subject')}
          </Text>
          <Text
            type="H4"
            mt={16}
          >
            {t('medium')}
          </Text>
          <Text
            type="H4"
            mt={16}
          >
            {t('measurement')}
          </Text>
          <Text
            type="H4"
            mt={16}
          >
            {t('orientation')}
          </Text>
          <Text
            type="H4"
            mt={16}
          >
            {t('artwork_description')}
          </Text>
        </div>

        <div>
          <Text
            type="H4"
            fontWeightBold
            data-testid={TESTID.ARTWORK_TITLE}
          >
            {artwork?.title || '--'}
          </Text>
          <Text
            mt={16}
            type="H4"
            fontWeightBold
            data-testid={TESTID.ARTIST_NAME}
          >
            {artwork?.artist?.name || artwork?.artist_name || '--'}
          </Text>
          <Text
            type="H4"
            mt={16}
            fontWeightBold
            data-testid={TESTID.CREATION_YEAR}
          >
            {artwork?.year_created || artwork?.period_created || '--'}
          </Text>
          <Text
            type="H4"
            mt={16}
            fontWeightBold
            data-testid={TESTID.EDITION}
          >
            {artwork?.total_edition}
          </Text>
          <Text
            type="H4"
            mt={16}
            fontWeightBold
          >
            {getLabelForOption(artwork?.style, language) || '--'}
          </Text>
          <Text
            type="H4"
            mt={16}
            fontWeightBold
          >
            {getLabelForOption(artwork?.subject, language) || '--'}
          </Text>
          <Text
            type="H4"
            mt={16}
            fontWeightBold
            data-testid={TESTID.MEDIUM}
          >
            {getLabelForOption(artwork?.medium, language) || '--'}
          </Text>
          <Text
            type="H4"
            mt={16}
            fontWeightBold
            data-testid={TESTID.MEASUREMENT}
          >
            {artwork?.size?.height && artwork?.size?.width
              ? artwork?.size?.depth
                ? `${artwork?.size?.height} cm x ${artwork?.size?.width} cm x ${artwork?.size?.depth} cm`
                : `${artwork?.size?.height} cm x ${artwork?.size?.width} cm`
              : '--'}
          </Text>
          <Text
            type="H4"
            mt={16}
            fontWeightBold
          >
            {getLabelForOption(artwork?.orientation, language) || '--'}
          </Text>
          <Text
            type="H4"
            mt={16}
            fontWeightBold
            data-testid={TESTID.MEDIUM}
          >
            {artwork?.description || '--'}
          </Text>
        </div>
      </Stack>
    </Section>
  );
};

export default ReviewArtworkInformation;
