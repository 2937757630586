import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import OwnerInfoRequestCer from 'features/Certificate/OwnerInfoRequestCer';
import ShippingInfo from 'features/Certificate/ShippingInfo';
import { artworkInfoSchema } from 'pages/RequestCertificate/CollectorRequestCertificate/validations';

import ArtworkInfo from './components/ArtworkInfo';
import RequestToArtist from './components/RequestToArtist';

const MainContentWrapper = ({ data }) => {
  const { t } = useTranslation();

  const { artwork, edition, request_to, images, key_images, owner_info, shipping_info } = data;

  const artworkFormState = useForm({
    values: {
      titleArtwork: artwork?.title,
      height: artwork?.size?.height,
      width: artwork?.size?.width,
      depth: artwork?.size?.depth,
      yearCreated: artwork?.year_created,
      periodYear: artwork?.period_created,
      mediumArtWork: artwork?.medium,
      editionVolume: artwork?.total_edition,
      edition,
      ownerName: owner_info?.name,
      yearOfBirth: owner_info?.year_of_birth,
      identityCardNumber: owner_info?.id_card,
      ownerAddress: owner_info?.address,
      recipient: shipping_info?.recipient,
      address: shipping_info?.address,
      phoneNumber: shipping_info?.phone_number,
      artistName: artwork?.artist_name || artwork?.artist_tag_request?.request_to?.name,
    },
    mode: 'onChange',
    resolver: yupResolver(artworkInfoSchema),
  });

  const { register, formState } = artworkFormState;

  return (
    <>
      <RequestToArtist
        requestTo={request_to}
        label={t('artist')}
      />
      {artwork && Object.keys(artwork || {}).length > 0 && (
        <ArtworkInfo
          artwork={artwork}
          images={images}
          keyImages={key_images}
          artworkFormState={artworkFormState}
        />
      )}
      <OwnerInfoRequestCer
        ownerName={owner_info?.name}
        yearOfBirth={owner_info?.year_of_birth}
        address={owner_info?.address}
      />
      <ShippingInfo
        shippingInfo={shipping_info}
        errors={formState?.errors}
        register={register}
      />
    </>
  );
};

export default MainContentWrapper;
