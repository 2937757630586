import { TableHead, TableRow } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import TableColumn from 'commons/TableColumn';
import useRole from 'hooks/useRole';

import useStyles from './styles';

const TableArtistTagRequestHead = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { isCollector } = useRole();

  return (
    <TableHead className={classes.tableHead}>
      <TableRow>
        <TableColumn
          text={t('#')}
          colStyle={classes.columnMini}
        />

        <TableColumn
          text={t('artwork_name')}
          colStyle={classes.columnLongWidth}
        />

        <TableColumn
          text={t('medium')}
          colStyle={classes.columnWidth}
        />

        <TableColumn
          text={t('year')}
          colStyle={classes.columnWidth}
        />

        <TableColumn
          text={t('measurement')}
          colStyle={classes.columnWidth}
        />

        <TableColumn
          text={t('edition_volume')}
          colStyle={classes.columnWidth}
        />

        <TableColumn
          text={t('requestor')}
          colStyle={classes.columnWidth}
        />
        {isCollector && (
          <TableColumn
            text={t('receiver')}
            colStyle={classes.columnWidth}
          />
        )}
        <TableColumn
          text={t('requested_date')}
          colStyle={classes.columnWidth}
        />

        <TableColumn
          text={t('status')}
          colStyle={classes.columnWidth}
        />

        <TableColumn
          text={t('action')}
          colStyle={classes.columnWidth}
        />
      </TableRow>
    </TableHead>
  );
};

export default TableArtistTagRequestHead;
