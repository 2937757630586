const API_ROOT = process.env.REACT_APP_API_URL;

const API = {
  AUTH: {
    LOGIN: `${API_ROOT}/accounts/login/`,
    LOGIN_SOCIAL_FACEBOOK: `${API_ROOT}/accounts/login/facebook/`,
    LOGIN_SOCIAL_GOOGLE: `${API_ROOT}/accounts/login/google/`,
    ACCOUNT_INFO: `${API_ROOT}/accounts/me/`,
    REMOVE_BANNER: `${API_ROOT}/accounts/me/remove_background/`,
    SIGNUP: `${API_ROOT}/accounts/register/phone/`,
    LOCATION: `${API_ROOT}/accounts/location/`,
    PROFILE_INFO: `${API_ROOT}/accounts/profile/`,
    USER_VISIBLE_SETTING: `${API_ROOT}/accounts/user_visible_setting/`,
    AWARD: {
      LIST: `${API_ROOT}/accounts/award/`,
      CREATE: `${API_ROOT}/accounts/award/`,
      DETAIL: (id) => `${API_ROOT}/accounts/award/${id}/`,
    },
    SOLO_EXHIBITION: {
      LIST: `${API_ROOT}/accounts/solo_exhibition/`,
      CREATE: `${API_ROOT}/accounts/solo_exhibition/`,
      DETAIL: (id) => `${API_ROOT}/accounts/solo_exhibition/${id}/`,
    },
    GROUP_EXHIBITION: {
      LIST: `${API_ROOT}/accounts/group_exhibition/`,
      CREATE: `${API_ROOT}/accounts/group_exhibition/`,
      DETAIL: (id) => `${API_ROOT}/accounts/group_exhibition/${id}/`,
    },
    PUBLICATION: {
      LIST: `${API_ROOT}/accounts/publication/`,
      CREATE: `${API_ROOT}/accounts/publication/`,
      DETAIL: (id) => `${API_ROOT}/accounts/publication/${id}/`,
    },
    ARTIST: {
      LIST: `${API_ROOT}/accounts/artist/`,
      SEARCH_USER: `${API_ROOT}/accounts/search_user/`,
    },
    REGISTER_EMAIL: `${API_ROOT}/accounts/register/email/`,
    USER_INVITATION: `${API_ROOT}/accounts/user_invitation/`,
    SEARCH_EMAIL_AND_ROLE: `${API_ROOT}/accounts/search_email_and_role/`,
    SEARCH_USER: `${API_ROOT}/accounts/search_user/`,
    SAVED_USER: `${API_ROOT}/accounts/saved_user/`,
  },
  ARTWORK: {
    ARTWORK: `${API_ROOT}/artwork/artwork/`,
    ARTWORK_DETAIL: (id) => `${API_ROOT}/artwork/artwork/${id}/`,
    ARTWORK_VIEW_INFO: (artworkId) => `${API_ROOT}/artwork/artwork/${artworkId}/artwork_view_info/`,
    DELETE_ARTWORK: (id) => `${API_ROOT}/artwork/artwork/${id}/`,
    CATEOGRY: `${API_ROOT}/artwork/category/`,
    STATUS: `${API_ROOT}/artwork/status/`,
    STATUS_CAN_REQUEST_CERTIFICATE: `${API_ROOT}/artwork/status/can_request_certificate/`,
    REQUEST_CERTIFICATE: (artworkId) =>
      `${API_ROOT}/artwork/artwork/${artworkId}/request_certificate/`,
    EDITIONS_CAN_REQUEST_CERTIFICATE: (artworkId) =>
      `${API_ROOT}/artwork/artwork/${artworkId}/editions_can_request_certificate/`,
    EDITION: `${API_ROOT}/artwork/edition/`,
    EDITION_UPDATE_STATUS: (edition_id) => `${API_ROOT}/artwork/edition/${edition_id}/`,
    DELETE_ARTWORK_EDITION: (edition_id) => `${API_ROOT}/artwork/edition/${edition_id}/delete/`,
    FILTER: {
      STYLE: `${API_ROOT}/artwork/filters/style/`,
      SUBJECT: `${API_ROOT}/artwork/filters/subject/`,
      COLOR: `${API_ROOT}/artwork/filters/color/`,
      ORIENTATION: `${API_ROOT}/artwork/filters/orientation/`,
      MEDIUM: `${API_ROOT}/artwork/filters/medium/`,
      ARTIST_BY_USER: `${API_ROOT}/artwork/filters/artist_by_user/`,
      LOCATION: `${API_ROOT}/artwork/filters/location/`,
    },
    SIGNATURE_EXTRACTION: `${API_ROOT}/artwork/signature_extraction/`,
    CERTIFICATE: `${API_ROOT}/artwork/certificate/`,
    LIST_CERTIFICATE_REQUEST: `${API_ROOT}/artwork/list_certificate_request/`,
    COLLECTOR_REQUEST_CERTIFICATE: (artworkId) =>
      `${API_ROOT}/artwork/artwork/${artworkId}/collector_request_certificate/`,
    APPROVE_CERTIFICATE_REQUEST: (id) =>
      `${API_ROOT}/artwork/certificate_request/${id}/approve_certificate_request/`,
    REJECT_CERTIFICATE_REQUEST: (id) =>
      `${API_ROOT}/artwork/certificate_request/${id}/reject_certificate_request/`,
    REVIEW_CERTIFICATE_REQUEST: (id) =>
      `${API_ROOT}/artwork/certificate_request/${id}/review_certificate_request/`,
    CERTIFICATE_REQUEST_DETAIL: (id) => `${API_ROOT}/artwork/certificate_request/${id}/`,
    LIST_ELIGIBLE_ARTWORK: `${API_ROOT}/artwork/artwork/list_eligible_artwork/`,
    LIST_GALLERY_ARTWORK: `${API_ROOT}/artwork/artwork/list_gallery_artwork/`,
    LIST_ARTWORK_SAME_COLLECTION: `${API_ROOT}/artwork/artwork/list_artwork_same_collection/`,
    SEARCH_AUTOCOMPLETE: `${API_ROOT}/artwork/search_autocomplete/`,
    EDITIONS_BULK_UPDATE: `${API_ROOT}/artwork/editions/bulk_update/`,
    REVIEW_ARTIST_TAG_REQUEST: (id) =>
      `${API_ROOT}/artwork/artist_tag_request/${id}/review_artist_tag_request/`,
    REJECT_ARTIST_TAG_REQUEST: (id) =>
      `${API_ROOT}/artwork/artist_tag_request/${id}/reject_artist_tag_request/`,
    APPROVE_ARTIST_TAG_REQUEST: (id) =>
      `${API_ROOT}/artwork/artist_tag_request/${id}/approve_artist_tag_request/`,
    RESEND_ARTIST_TAG_REQUEST: (id) =>
      `${API_ROOT}/artwork/artist_tag_request/${id}/resend_artist_tag_request/`,
    DELETE_ARTIST_TAG_REQUEST: (id) => `${API_ROOT}/artwork/artist_tag_request/${id}/`,
    CANCEL_ARTIST_TAG_REQUEST: (id) =>
      `${API_ROOT}/artwork/artist_tag_request/${id}/cancel_artist_tag_request/`,
    LIST_ARTIST_TAG_REQUEST: `${API_ROOT}/artwork/artist_tag_request/`,
    LIST_ARTWORK_SELECTION: `${API_ROOT}/artwork/artwork/list_artworks_for_selection/`,
    COLLECTION: `${API_ROOT}/artwork/collection/`,
    COLLECTION_DETAIL: (id) => `${API_ROOT}/artwork/collection/${id}/`,
    LIST_OWNER_COLLECTION: `${API_ROOT}/artwork/collection/list_owner_collection/`,
    LIKE_COLLECTION: (id) => `${API_ROOT}/artwork/collection/${id}/like/`,
    UN_LIKE_COLLECTION: (id) => `${API_ROOT}/artwork/collection/${id}/unlike/`,
    LIKE_ARTWORK: (id) => `${API_ROOT}/artwork/artwork/${id}/like/`,
    UN_LIKE_ARTWORK: (id) => `${API_ROOT}/artwork/artwork/${id}/unlike/`,
    ARTWORK_COUNT: `${API_ROOT}/artwork/artwork_count/`,
    COLLECTION_COUNT: `${API_ROOT}/artwork/collection_count/`,
    LIST_COLLECTION_SAME_ARTIST: `${API_ROOT}/artwork/collection/list_collection_same_artist/`,
    LIST_ARTWORK_EXCLUDING_SOLD_DONATED_GIFTED: `${API_ROOT}/artwork/artwork/list_artwork_excluding_sold_donated_gifted/`,
    LIST_GALLERY_COLLECTION: `${API_ROOT}/artwork/collection/list_gallery_collection/`,
    CREATE_SHARE_LINK: `${API_ROOT}/artwork/artwork/create_share_link/`,
    LIST_ARTIST_NAME_FOR_COLLECTOR: `${API_ROOT}/artwork/artwork/list_artist_name_for_collector/`,
  },
  AWS: {
    S3_PRESIGNED: `${API_ROOT}/common/s3_presigned/`,
  },
  ACTIVITY_LOG: {
    CERTIFICATE_EXPORT_LOG: `${API_ROOT}/activity_log/certificate_export_logs/`,
  },
};

export default API;
