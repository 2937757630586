import clsx from 'clsx';
import React from 'react';
import { useSelector } from 'react-redux';

import { getLabelForOption } from 'configs/Constant';

import TextInfo from './TextInfo';
import useStyles from './styles';

const ArtworkItemInfo = ({ artwork }) => {
  const classes = useStyles();
  const language = useSelector((state) => state?.language?.currentLanguage);
  const { title, period_created, year_created, size, medium } = artwork || {};

  const sizeData =
    size?.height &&
    size?.width &&
    `${size?.height} x ${size?.width} ${size?.depth ? ` x ${size?.depth}` : ''}cm `;

  return (
    <div className={classes.wrapInfo}>
      <div className={classes.row}>
        <TextInfo
          value={title}
          fontWeightBold
        />
        <TextInfo value={year_created || period_created} />
      </div>

      <div className={clsx(classes.row, classes.spacingTop)}>
        <TextInfo value={getLabelForOption(medium, language)} />
        <TextInfo value={sizeData} />
      </div>
    </div>
  );
};

export default ArtworkItemInfo;
