import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  wrapImage: {
    position: 'relative',
    '&:hover': {
      '& $popOverDot': {
        opacity: 0.5,
        visibility: 'visible',
      },
      '& $wrapLikeUI': {
        display: 'block',
      },
    },
  },
  popOverDot: {
    opacity: 0,
  },
  image: {
    cursor: 'pointer',
  },

  tooltip: {
    '&.MuiTooltip-tooltip': {
      padding: '6px 8px',
      fontSize: '1.2rem',
      lineHeight: '150%',
      backgroundColor: Colors.Primary,
    },
  },
  tooltipArrow: {
    '&.MuiTooltip-arrow': {
      color: Colors.Primary,
    },
  },
  redDot: {
    width: 12,
    height: 12,
    background: Colors.Error,
    borderRadius: '50%',
  },
  text: {
    flex: 1,
  },
  wrapLikeUI: {
    display: 'none',
  },
  wrapIconPrivate: {
    width: 22,
    height: 22,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 16,
    right: 42,
    borderRadius: 2,
    background: Colors.Black,
    opacity: 0,
  },
}));

export default useStyles;
