import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  container: {},
  mainImageBlock: {
    background: Colors.Grey2,
    aspectRatio: '310 / 206',
  },
  image: {
    display: 'block',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  noneImage: {
    display: 'none',
  },
  subImage: {
    display: 'block',
    width: '100%',
    height: 75,
    objectFit: 'cover',
  },
  wrapImage: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center ',
    alignItems: 'center',
    gap: '2px',
    marginTop: '2px',
  },
}));

export default useStyles;
