import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  wrapArtwork: {
    '&:hover': {
      '& $textOwner': {
        opacity: 0,
      },
    },
  },
  textOwner: {
    opacity: 1,
  },
  wrapOwnerInfo: {
    width: '100%',
    padding: 16,
    paddingTop: 4,
    position: 'absolute',
    left: 0,
    bottom: 0,
    background: 'linear-gradient(0deg, #000 -36.9%, rgba(26, 26, 26, 0.00) 100%)',
    opacity: 1,
    transition: 'all 0.5s',
    zIndex: 0,
  },
  redDot: {
    width: 12,
    height: 12,
    background: Colors.Error,
    borderRadius: '50%',
    position: 'absolute',
    bottom: 22,
    right: 16,
  },

  tooltip: {
    '&.MuiTooltip-tooltip': {
      padding: '6px 8px',
      fontSize: '1.2rem',
      lineHeight: '150%',
      backgroundColor: Colors.Primary,
    },
  },
  tooltipArrow: {
    '&.MuiTooltip-arrow': {
      color: Colors.Primary,
    },
  },
}));

export default useStyles;
