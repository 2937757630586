import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  wrapper: {
    flex: 1,
    background: Colors.White,
    paddingBottom: 142,
  },
  gridContainer: {
    '&.MuiGrid-container': {
      marginTop: 24,
    },
  },
  pagination: {
    '&.MuiPagination-root': {
      marginTop: 40,
      display: 'flex',
      justifyContent: 'center',
    },
  },
}));

export default useStyles;
