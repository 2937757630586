import { useQuery } from '@tanstack/react-query';
import { useSearchParams } from 'react-router-dom';

import API from 'configs/API';
import { createPersister } from 'react-query';

const useEligibleArtworks = () => {
  const [searchParams] = useSearchParams();
  const objectParamsFromSearchParams = Object.fromEntries(searchParams);

  const params = {
    ...objectParamsFromSearchParams,
  };

  return useQuery({
    queryKey: [API.ARTWORK.LIST_ELIGIBLE_ARTWORK, params],
    persister: createPersister(),
    placeholderData: {},
  });
};

export default useEligibleArtworks;
