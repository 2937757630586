import { Container } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import API from 'configs/API';
import GridLayout from 'layouts/GridLayout';
import Routes from 'utils/Route';

import ArtworkDetailLeft from './components/ArtworkDetailLeft';
import ArtworkDetailRight from './components/ArtworkDetailRight';

const ArtworkDetail = () => {
  const { t } = useTranslation();

  const { id } = useParams();
  const user = useSelector((state) => state.auth.account.user);

  const { data: artwork = {}, isPending } = useQuery({
    queryKey: [API.ARTWORK.ARTWORK_VIEW_INFO(id)],
  });

  const breadCrumbs = [
    {
      to: Routes.Home,
      text: t('home'),
    },
    {
      to: Routes.GalleryDetail(user?.uuid),
      text: t('gallery'),
    },
    {
      to: Routes.ArtworkDetail(id),
      text: t('artwork_detail'),
      active: true,
    },
  ];

  if (isPending) {
    return;
  }

  return (
    <Container>
      <GridLayout
        spacing={5}
        leftColumnProps={{
          lg: 3,
        }}
        rightColumnProps={{
          lg: 9,
        }}
      >
        <ArtworkDetailLeft
          breadCrumbs={breadCrumbs}
          artwork={artwork}
        />
        <ArtworkDetailRight
          loading={isPending}
          artwork={artwork}
        />
      </GridLayout>
    </Container>
  );
};

export default ArtworkDetail;
