import { useQueryClient } from '@tanstack/react-query';
import React, { createContext, useContext, useEffect, useState } from 'react';

import API from 'configs/API';
import useLikeArtwork from 'hooks/artwork/useLikeArtwork';
import useUnLikeArtwork from 'hooks/artwork/useUnlikeArtwork';

const ArtworkLikeStatContext = createContext();

const ArtworkLikeStatProvider = ({ artwork, children }) => {
  const [isLiked, setIsLiked] = useState(!!artwork?.is_user_like);
  const [numberOfLike, setNumberOfLike] = useState(artwork?.number_of_likes);
  const [shouldUpdate, setShouldUpdate] = useState(true);

  const { mutate: likeArtwork, isPending: isLikePeding } = useLikeArtwork();
  const { mutate: unLikeArtwork, isPending: isUnLikePending } = useUnLikeArtwork();

  const queryClient = useQueryClient();

  const handleLikeArtwork = () => {
    setIsLiked((prev) => !prev);
    setNumberOfLike((prev) => prev + 1);

    likeArtwork(artwork?.id, {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [API.ARTWORK.ARTWORK_DETAIL(artwork?.id)],
        });
      },
      onError: () => {
        setIsLiked((prev) => !prev);
        setNumberOfLike((prev) => prev - 1);
      },
    });
  };

  const handleUnLikeArtwork = () => {
    setIsLiked((prev) => !prev);
    setNumberOfLike((prev) => prev - 1);

    unLikeArtwork(artwork?.id, {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [API.ARTWORK.ARTWORK_DETAIL(artwork?.id)],
        });
      },
      onError: () => {
        setIsLiked((prev) => !prev);
        setNumberOfLike((prev) => prev + 1);
      },
    });
  };

  const handleClickLikeButton = () => {
    setShouldUpdate(false);
    isLiked ? handleUnLikeArtwork() : handleLikeArtwork();
  };

  const isPending = isLikePeding || isUnLikePending;

  useEffect(() => {
    if (!shouldUpdate) {
      return;
    }
    setIsLiked(!!artwork.is_user_like);
  }, [artwork.is_user_like, shouldUpdate]);

  useEffect(() => {
    if (!shouldUpdate) {
      return;
    }
    setNumberOfLike(artwork.number_of_likes);
  }, [artwork.number_of_likes, shouldUpdate]);

  const value = {
    onClick: handleClickLikeButton,
    isLiked,
    numberOfLike,
    isPending,
  };

  return (
    <ArtworkLikeStatContext.Provider value={value}>{children}</ArtworkLikeStatContext.Provider>
  );
};

export default ArtworkLikeStatProvider;

export const useArtworkLikeStatContext = () => {
  return useContext(ArtworkLikeStatContext);
};
