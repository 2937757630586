import { Box, Container } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useSearchParams } from 'react-router-dom';

import ArtCatalogHeader from 'commons/ArtCatalogHeader';
import Breadcrumbs from 'commons/Breadcrumbs';
import PaginationCustom from 'commons/PaginationCustom';
import { CERTIFICATE, SHOW } from 'configs/Constant';
import usePagination from 'hooks/usePagination';
import GridLayout from 'layouts/GridLayout';
import Routes from 'utils/Route';

import EligibleArtworks from './components/EligibleArtworks';
import useEligibleArtworks from './hooks/useEligibleArtworks';
import useStyles from './styles';

const Home = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const certificateCode = searchParams.get('certificate_code');
  const { data: artworks, isPending } = useEligibleArtworks();
  const { page, totalPage, onPageChange } = usePagination(artworks?.count, artworks?.page_size);

  const breadCrumbs = [
    {
      to: Routes.Home,
      text: t('home'),
    },
    {
      text: t('artworks'),
      active: true,
    },
  ];

  const handlePageChange = (_, page) => {
    onPageChange(page);
  };

  if (certificateCode) {
    return <Navigate to={Routes.ArtworkDetail(`${certificateCode}?${CERTIFICATE}=${SHOW}`)} />;
  }

  return (
    <Box className={classes.wrapper}>
      <Container>
        <Box sx={{ pt: 3 }}>
          <Breadcrumbs items={breadCrumbs} />

          <Box sx={{ pt: 1 }}>
            <ArtCatalogHeader
              title={t('artworks')}
              placeholder={t('search_artwork_or_artist_name')}
            />

            <Box sx={{ pt: 3 }}>
              <GridLayout
                leftColumnProps={{ xs: 12, lg: 12 }}
                rightColumnProps={{ xs: 12, lg: 12 }}
              >
                {!isPending && <EligibleArtworks artworks={artworks} />}
              </GridLayout>
            </Box>

            {!!totalPage && (
              <PaginationCustom
                paginationStyle={classes.pagination}
                count={totalPage}
                page={page}
                onChange={handlePageChange}
              />
            )}
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Home;
