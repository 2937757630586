import { Tooltip } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Text from 'commons/Text';
import Colors from 'configs/Colors';
import { STATUS_CHOICES } from 'configs/Constant';

import useStyles from './styles';

const ArtworkItemOwnerInfo = ({ ownerName, showTooltip, artworkStatus }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const getTooltipText = () => {
    switch (artworkStatus?.key) {
      case STATUS_CHOICES.SOLD:
        return t('in_transaction_process');
      case STATUS_CHOICES.NOT_FOR_SALE:
        return t('not_for_sale');
      default:
        break;
    }
  };

  const toolTipText = getTooltipText();

  return (
    <div className={classes.wrapOwnerInfo}>
      <Text
        color={Colors.White}
        type="lg"
        className={classes.textOwner}
      >
        {ownerName}
      </Text>

      {toolTipText && (
        <Tooltip
          title={toolTipText}
          arrow
          open={showTooltip}
          placement="top"
          classes={{
            tooltip: classes.tooltip,
            arrow: classes.tooltipArrow,
          }}
        >
          <div className={classes.redDot}></div>
        </Tooltip>
      )}
    </div>
  );
};

export default ArtworkItemOwnerInfo;
