import { Grid } from '@mui/material';
import React from 'react';

import useStatusCanRequestCertificate from 'react-query/hooks/queries/useStatusCanRequestCertificate';

import EligibleArtworkItem from '../EligibleArtworkItem';

const ListArtwork = ({ listArtwork }) => {
  const { data: status } = useStatusCanRequestCertificate();

  return (
    <>
      <Grid
        container
        spacing={3}
      >
        {listArtwork?.map((item) => (
          <Grid
            key={item?.id}
            item
            xs={12}
            lg={3}
          >
            <EligibleArtworkItem
              artwork={item}
              status={status}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default ListArtwork;
