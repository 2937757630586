import { Avatar, Button, Stack } from '@mui/material';
import moment from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import CollectionLikeStat from 'commons/Collection/CollectionLikeStat';
import LinkRef from 'commons/LinkRef';
import ShareSocialPopup from 'commons/ShareSocialPopup';
import Text from 'commons/Text';
import { ReactComponent as PenEdit } from 'images/pen_edit.svg';
import { ReactComponent as ShareIcon } from 'images/share.svg';
import Routes from 'utils/Route';

import useStyles from './styles';

const CollectionHeader = ({ collection }) => {
  const { id } = useParams();

  const { t } = useTranslation();
  const classes = useStyles();
  const user = useSelector((state) => state.auth.account.user);

  const [isOpenSharePopup, setIsOpenSharePopup] = useState(false);

  const dayCreated = moment(collection?.created_at);
  const isOwner = user?.id === parseInt(collection?.owner?.id);

  const handleOpenSocialPopup = () => {
    setIsOpenSharePopup(true);
  };

  const handleCloseSocialPopup = () => {
    setIsOpenSharePopup(false);
  };

  return (
    <>
      <Stack
        direction="row"
        sx={{ justifyContent: 'space-between', alignItems: 'center' }}
      >
        <Text
          variant="h1"
          fontWeightBold
          type="3xl"
        >
          {collection?.title}
        </Text>

        <Stack
          direction="row"
          spacing={2}
        >
          {isOwner && (
            <Button
              component={LinkRef}
              to={Routes.EditCollection(collection?.id)}
              variant="outlined"
              className={classes.btn}
            >
              <PenEdit className={classes.icon} />
              <Text
                variant="span"
                ml={8}
              >
                {t('edit')}
              </Text>
            </Button>
          )}

          <Button
            variant="outlined"
            onClick={handleOpenSocialPopup}
          >
            <ShareIcon className={classes.icon} />
            <Text
              variant="span"
              ml={8}
            >
              {t('share')}
            </Text>
          </Button>
        </Stack>
      </Stack>

      <Stack
        direction="row"
        sx={{ alignItems: 'center', mt: 2 }}
      >
        <Avatar
          alt="Avatar"
          src={collection?.owner?.avatar}
          className={classes.avatar}
        />

        <Text
          variant={Link}
          to={Routes.GalleryDetail(collection?.owner?.uuid)}
          ml={8}
          type="sm"
          fontWeightMedium
          className={classes.textUserName}
        >
          {collection?.owner?.name}
        </Text>
      </Stack>

      <div className={classes.wrapInfo}>
        <Text
          type="sm"
          className={classes.infoItem}
        >
          {collection?.artworks?.length} {t('artworks')}
        </Text>
        <Text
          type="sm"
          className={classes.infoItem}
        >
          {t('day_created')}: {dayCreated.format('DD/MM/YYYY')}
        </Text>

        <CollectionLikeStat
          collection={collection}
          redirectTo={Routes.CollectionDetail(id)}
        />
      </div>

      <Text mt={16}>{collection?.description}</Text>

      <ShareSocialPopup
        open={isOpenSharePopup}
        onClose={handleCloseSocialPopup}
        fullWidth
        maxWidth="sm"
      />
    </>
  );
};

export default CollectionHeader;
