import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import CheckBoxGroup from 'commons/FilterArtwork/CheckBoxGroup';
import API from 'configs/API';
import { ARTIST_KEY, ARTWORK_FILTER_PARAMS } from 'configs/FilterArtwork';

const { ARTIST } = ARTWORK_FILTER_PARAMS;

const FilterArtist = () => {
  const user = useSelector((state) => state.auth.account.user);

  const { data: artists = [] } = useQuery({
    queryKey: [API.ARTWORK.FILTER.ARTIST_BY_USER, { user_id: user?.id }],
  });
  const [searchParams, setSearchParams] = useSearchParams();

  const selectedArtistParams = searchParams.get(ARTIST)?.split(',') || [];

  const handleCheckboxChange = (artist) => {
    const isChecked = selectedArtistParams.find(
      (item) => item === artist[ARTIST_KEY.CHECKED]?.toString(),
    );

    searchParams.delete('page');

    if (!isChecked) {
      searchParams.set(ARTIST, [...selectedArtistParams, artist[ARTIST_KEY.CHECKED]]);
      setSearchParams(searchParams);
      return;
    }

    const newSelectArtistParams = [...selectedArtistParams].filter(
      (item) => item !== artist[ARTIST_KEY.CHECKED]?.toString(),
    );

    if (newSelectArtistParams.length) {
      searchParams.set(ARTIST, newSelectArtistParams);
      setSearchParams(searchParams);
    } else {
      searchParams.delete(ARTIST);
      setSearchParams(searchParams);
    }
  };

  return (
    <CheckBoxGroup
      listCheckBox={artists}
      selectedFilters={selectedArtistParams}
      onCheckboxChange={handleCheckboxChange}
      textDisplayKey={ARTIST_KEY.DISPLAY}
      checkedKey={ARTIST_KEY.CHECKED}
    />
  );
};

export default FilterArtist;
