import { ImageListItem, Stack, Tooltip } from '@mui/material';
import clsx from 'clsx';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import ArtworkItemPopover from 'commons/ArtworkItem/ArtworkItemPopover';
import ArtworkLikeStatProvider from 'commons/ArtworkLikeStatProvider';
import Image from 'commons/Image';
import Text from 'commons/Text';
import { STATUS_CHOICES, getLabelForOption } from 'configs/Constant';
import { ReactComponent as KeyPrivate } from 'images/key_private.svg';
import Routes from 'utils/Route';

import ArtworkLikeUI from '../ArtworkLikeUI';
import TextInfo from './TextInfo';
import useStyles from './styles';

const GalleryArtworkItem = ({ artwork, status, isOwner }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const language = useSelector((state) => state?.language?.currentLanguage);
  const [showTooltip, setShowTooltip] = useState(false);
  const { size, medium, is_public: isPublic } = artwork || {};

  const sizeData =
    size?.height &&
    size?.width &&
    `${size?.height} x ${size?.width} ${size?.depth ? ` x ${size?.depth}` : ''}cm `;

  const { certificate } = artwork || {};
  const { code } = certificate || {};

  const handleClickArtwork = () => {
    navigate(Routes.ArtworkDetail(code || artwork?.id));
  };

  const getTooltipText = () => {
    switch (artwork?.status?.key) {
      case STATUS_CHOICES.SOLD:
        return t('in_transaction_process');
      case STATUS_CHOICES.NOT_FOR_SALE:
        return t('not_for_sale');
      default:
        break;
    }
  };

  const toolTipText = getTooltipText();

  const handleMouseHover = () => {
    if (!toolTipText) {
      return;
    }
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    if (!toolTipText) {
      return;
    }
    setShowTooltip(false);
  };

  return (
    <ImageListItem>
      <div className={classes.wrapImage}>
        <Image
          src={artwork?.images[0]}
          imageStyle={classes.image}
          onClick={handleClickArtwork}
          onMouseOver={handleMouseHover}
          onMouseLeave={handleMouseLeave}
        />

        {isOwner && !isPublic && (
          <div className={clsx(classes.wrapIconPrivate, classes.popOverDot)}>
            <KeyPrivate />
          </div>
        )}

        {isOwner && (
          <ArtworkItemPopover
            artwork={artwork}
            status={status}
            showPopover={isOwner}
            dotStyle={classes.popOverDot}
          />
        )}

        <ArtworkLikeStatProvider artwork={artwork}>
          <ArtworkLikeUI wrapStyle={classes.wrapLikeUI} />
        </ArtworkLikeStatProvider>
      </div>

      <div>
        <Stack
          direction="row"
          sx={{ alignItems: 'flex-start', justifyContent: 'space-between', mt: 1 }}
        >
          <Text
            fontWeightMedium
            type="sm"
            className={classes.text}
          >
            {artwork?.title}
          </Text>

          {toolTipText && (
            <Tooltip
              title={toolTipText}
              arrow
              open={showTooltip}
              placement="top"
              classes={{
                tooltip: classes.tooltip,
                arrow: classes.tooltipArrow,
              }}
            >
              <div className={classes.redDot}></div>
            </Tooltip>
          )}
        </Stack>

        <Stack
          direction="row"
          flexWrap="wrap"
          spacing={1}
          sx={{ mt: 0.5 }}
        >
          <TextInfo value={getLabelForOption(medium, language)} />
          <TextInfo value={sizeData} />
        </Stack>
      </div>
    </ImageListItem>
  );
};

export default GalleryArtworkItem;
