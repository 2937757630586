import clsx from 'clsx';
import React from 'react';
import LazyLoad from 'react-lazyload';
import { useNavigate } from 'react-router-dom';

import Image from 'commons/Image';
import { TESTID } from 'configs/Constant';
import Routes from 'utils/Route';

import ArtworkItemInfo from './ArtworkItemInfo';
import ArtworkItemPopover from './ArtworkItemPopover';
import useStyles from './styles';

const ArtworkItem = ({
  artwork,
  status,
  showPopover,
  extraUi,
  wrapArtworkStyle,
  onMouseOver,
  onMouseLeave,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const { certificate } = artwork || {};
  const { code } = certificate || {};

  const handleClickArtwork = () => {
    navigate(Routes.ArtworkDetail(code || artwork?.id));
  };

  return (
    <div
      testid={TESTID.ART_WORK_ITEM}
      className={clsx(classes.wrapArtwork, wrapArtworkStyle)}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
    >
      <LazyLoad
        height={150}
        offset={5}
        once
      >
        <Image
          src={artwork?.images[0]}
          imageStyle={classes.image}
          onClick={handleClickArtwork}
          testid={TESTID.ART_WORK_ITEM_IMAGE}
        />
      </LazyLoad>

      <ArtworkItemInfo artwork={artwork} />

      {extraUi}

      {showPopover && (
        <ArtworkItemPopover
          artwork={artwork}
          status={status}
          testid={TESTID.POPOVER_ARTWORK}
        />
      )}
    </div>
  );
};

export default ArtworkItem;
