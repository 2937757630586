import clsx from 'clsx';
import React from 'react';

import Image from 'commons/Image';

import useStyles from './styles';

const ImageSection = ({ artworks }) => {
  const classes = useStyles();

  const [artwork1, artwork2, artwork3] = artworks || [];

  return (
    <div className={classes.container}>
      <div className={classes.mainImageBlock}>
        <Image
          src={artwork1?.images[0]}
          className={clsx(classes.image, { [classes.noneImage]: !artwork1?.images[0] })}
        />
      </div>

      <div className={classes.sideImageBlock}>
        <Image
          src={artwork2?.images[0]}
          className={clsx(classes.image, { [classes.noneImage]: !artwork2?.images[0] })}
        />
      </div>

      <div className={classes.sideImageBlock}>
        <Image
          src={artwork3?.images[0]}
          className={clsx(classes.image, { [classes.noneImage]: !artwork3?.images[0] })}
        />
      </div>
    </div>
  );
};

export default ImageSection;
