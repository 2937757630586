import React from 'react';
import { useSelector } from 'react-redux';

import { USER_ROLE_BY_KEY } from 'configs/Constant';

import TableArtist from '../TableArtworkByRole/TableArtist';
import TableCollector from '../TableArtworkByRole/TableCollector';

const { ARTIST, COLLECTOR } = USER_ROLE_BY_KEY;
const USER_TABLE_BY_ROLE = {
  [ARTIST]: TableArtist,
  [COLLECTOR]: TableCollector,
};

const TableManager = () => {
  const userRole = useSelector((state) => state.auth.account.user.role);

  const DisplayTable = USER_TABLE_BY_ROLE[userRole];

  if (!DisplayTable) {
    return;
  }

  return <DisplayTable />;
};

export default TableManager;
