import React from 'react';
import { useTranslation } from 'react-i18next';

import DialogCustom from 'commons/DialogCustom';
import FormInput from 'commons/Form/FormInput';
import Text from 'commons/Text';

import useStyles from './style';

const DialogArtistName = ({
  isShowDialog,
  disabledButton,
  onCancel,
  onSubmit,
  value,
  onChangeArtistName,
  onChangeEmailPhoneArtist,
  loading,
  ...props
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <DialogCustom
      isShowDialog={isShowDialog}
      disabledButton={disabledButton}
      loading={loading}
      dialogTitle={t('add_artist_name')}
      textSubmit={t('add')}
      textCancel={t('cancel')}
      onCancel={onCancel}
      onSubmit={onSubmit}
      {...props}
    >
      <FormInput
        value={value}
        isRequired={true}
        onChange={onChangeArtistName}
        label={t('artist_name')}
        placeholder={t('enter_artist_name')}
        labelStyle={classes.formInputLabel}
        textFieldStyle={classes.textFieldStyle}
        wrapFormInputStyle={classes.wrapFormInput}
      />

      <FormInput
        value={value}
        onChange={onChangeEmailPhoneArtist}
        label={t('email_phone_number')}
        placeholder={t('enter_artist_email_phone_number')}
        labelStyle={classes.formInputLabel}
        textFieldStyle={classes.textFieldStyle}
        wrapFormInputStyle={classes.wrapFormInput2}
      />

      <Text className={classes.textAdding}>{t('adding_the_artist_name_along')}</Text>
    </DialogCustom>
  );
};

export default DialogArtistName;
