import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { TESTID } from 'configs/Constant';
import LogoPlatForm from 'images/logo-platform.svg';
import Routes from 'utils/Route';

import useStyles from '../styles';

const HeaderLeft = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const navigateToArtistsPage = () => {
    navigate(Routes.Artists);
  };

  const navigateToHomePage = () => {
    navigate(Routes.Home);
  };

  const navigateToAboutUsPage = () => {
    navigate(Routes.AboutUs);
  };

  return (
    <div className={classes.wrapHeaderLeft}>
      <div className={classes.wrapGroupItem}>
        <img
          src={LogoPlatForm}
          onClick={navigateToAboutUsPage}
          className={classes.logoPlatForm}
          testid={TESTID.LOGO}
        />

        <div
          onClick={navigateToHomePage}
          className={classes.textItem}
        >
          {t('artworks')}
        </div>

        <div
          onClick={navigateToArtistsPage}
          className={classes.textItem}
        >
          {t('artists')}
        </div>

        {/* <div className={classes.textItem}>{t('exhibition')}</div>
        <div className={classes.textItem}>{t('gallery')}</div> */}
      </div>
    </div>
  );
};
export default HeaderLeft;
