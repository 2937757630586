import React, { useState } from 'react';

import ArtworkItem from 'commons/ArtworkItem';

import ArtworkItemOwnerInfo from './ArtworkItemOwnerInfo';
import useStyles from './styles';

const EligibleArtworkItem = ({ artwork, status }) => {
  const classes = useStyles();
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseHoverArtwork = () => {
    setShowTooltip(true);
  };

  const handleMouseLeaveHoverArtwork = () => {
    setShowTooltip(false);
  };

  return (
    <ArtworkItem
      artwork={artwork}
      status={status}
      extraUi={
        <ArtworkItemOwnerInfo
          ownerName={artwork?.owner?.name}
          artworkStatus={artwork?.status}
          showTooltip={showTooltip}
        />
      }
      wrapArtworkStyle={classes.wrapArtwork}
      onMouseOver={handleMouseHoverArtwork}
      onMouseLeave={handleMouseLeaveHoverArtwork}
    />
  );
};

export default EligibleArtworkItem;
