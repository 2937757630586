import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import Stepper from 'commons/Stepper';
import useStepper from 'hooks/useSteper';
import { artworkInfoSchema } from 'pages/RequestCertificate/CollectorRequestCertificate/validations';

import ReviewCertificateStep from '../ReviewCertificateStep';
import UpdateInfoStep from '../UpdateInfoStep';

const InfoCertificateRequest = ({ data }) => {
  const { artwork, edition, owner_info, shipping_info, message, request_to, images, key_images } =
    data;

  const { step, handlBackStep, handleNextStep } = useStepper(1);
  const [listUploadedImages, setListUploadedImages] = useState([]);
  const [mergedListImages, setMergedListImages] = useState([]);

  const artworkFormState = useForm({
    values: {
      artistNameArtwork: artwork?.artist_name || artwork?.artist_tag_request?.request_to?.name,
      titleArtwork: artwork?.title,
      height: artwork?.size?.height,
      width: artwork?.size?.width,
      depth: artwork?.size?.depth,
      yearCreated: artwork?.year_created,
      periodYear: artwork?.period_created,
      mediumArtWork: artwork?.medium,
      editionVolume: artwork?.total_edition,
      edition: edition,
      ownerName: owner_info?.name,
      yearOfBirth: owner_info?.year_of_birth,
      ownerAddress: owner_info?.address,
      recipient: shipping_info?.recipient,
      address: shipping_info?.address,
      phoneNumber: shipping_info?.phone_number,
      inputSearchUser: '',
      selectedUser: request_to,
    },
    mode: 'onChange',
    resolver: yupResolver(artworkInfoSchema),
  });

  return (
    <>
      <Stepper active={step === 1}>
        <UpdateInfoStep
          onNextStep={handleNextStep}
          artwork={artwork}
          artworkFormState={artworkFormState}
          listUploadedImages={listUploadedImages}
          message={message}
          images={images}
          keyImages={key_images}
          setListUploadedImages={setListUploadedImages}
          mergedListImages={mergedListImages}
          setMergedListImages={setMergedListImages}
          ownerInfo={owner_info}
        />
      </Stepper>

      <Stepper active={step === 2}>
        <ReviewCertificateStep
          onBackStep={handlBackStep}
          artworkFormState={artworkFormState}
          artwork={artwork}
          listUploadedImages={listUploadedImages}
          mergedListImages={mergedListImages}
        />
      </Stepper>
    </>
  );
};

export default InfoCertificateRequest;
